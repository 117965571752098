<script>
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
    props: {
        user: { type: Object },
    },
    data() {
        return {
            rolesList: [
                { text: 'Administrador', value: '3' },
                { text: 'Atendente', value: '4' },
                { text: 'Profiissional', value: '5' },
                { text: 'Cliente', value: '6' },
            ],
            submit: false
        }
    },
    validations: {
        user: {
            name: { required },
            email: { required },
        }
    },
    computed: {
        state() {
            return Boolean(this.user.role_id);
        }
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submit = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.user.$error) {
                Swal.fire('Oops...', 'Erro de validação!', 'error');
            } else {
                this.$emit("doPut", this.user);
            }
        },
        back() {
            this.$emit("back", "isEditing = false");
        },
        setRole(role) {
            this.user.role = role;
        }
    }
}
</script>
<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <img
                            src="@/assets/images/users/no-avatar.jpg"
                            class="img-fluid mx-auto d-block rounded-circle avatar-xl"
                            alt="user-avatar"
                        />
                        <div class="text-center">
                            <h4 class="card-title mt-3">{{ user.name }}</h4>
                            <h6 class="font-size-12 mb-1 mt-3">{{ user.email }}</h6>
                            <h2 class="mt-5 text-info">Informações</h2>
                            <p class="mt-2 mb-5 mx-5">
                                Altere as informações do usuário como nome, e-mail,
                                 perfil. Lembrando que para tal deve ter permissão.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" @submit.prevent="formSubmit">
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="mb-2 text-primary">Informações do usuário:</h6>
                                </div>
                                <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="name">Nome:</label>
                                                    <input
                                                     id="name"
                                                     type="text"
                                                     class="form-control"
                                                     v-model="user.name"
                                                     placeholder="Digite o nome para o Usuário"
                                                     :class="{ 'is-invalid': submit && $v.user.name.$error }"
                                                    />
                                                    <div v-if="submit && $v.user.name.$error" class="invalid-tooltip">
                                                        <span v-if="!$v.user.name.required">Por favor, informe o Nome do Usuário!</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="email">E-mail:</label>
                                                    <input
                                                     id="email"
                                                     type="text"
                                                     class="form-control"
                                                     v-model="user.email"
                                                     :class="{ 'is-invalid': submit && $v.user.email.$error }"
                                                    />
                                                    <div v-if="submit && $v.user.email.$error" class="invalid-tooltip">
                                                        <span v-if="!$v.user.email.required">Por favor, informe o E-mail do Usuário!</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="mt-3 mb-2 text-primary">Perfil:</h6>
                                </div>
                                <div class="col-md-12">
                                    <b-form-radio-group
                                     v-model="user.role_id"
                                     :state="state"
                                     buttons
                                    >
                                        <b-form-radio v-for="role in rolesList" :key="role.value" 
                                         :value="role.value"
                                         size="lg"
                                        >
                                            {{role.text}}
                                        </b-form-radio>
                                        <b-form-invalid-feedback :state="state">Por favor, seleccione um perfil</b-form-invalid-feedback>
                                    </b-form-radio-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h6 class="mt-3 mb-2 text-primary">Perfil</h6>
                                    <b-list-group
                                        class="list-unstyled mb-0"
                                        v-for="role in user.roles"
                                        :key="role.id"
                                    >
                                        <b-list-group-item>{{ role.description }}</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12">
                                    <div class="text-right">
                                        <b-button @click="back" type="button" variant="secondary">Cancelar</b-button>
                                        <b-button type="submit" variant="primary" class="ml-2">Salvar</b-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>