<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {http} from "../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Swal from "sweetalert2";
import Edit from "./edit.vue";

export default {
  page: {
    title: "Usuários do sistema",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Edit },
  data() {
    return {
      isEditing: false,
      userSelected: null,
      currentUser: null,
      currentEmpresa: null,
      submitted: false,
      users: [],
      title: "Listagem",
      items: [
          {
          text: "Dashboard",
          href: "/",
          },
          {
          text: "Usuários",
          href: "/userGetAll",
          },
          {
          text: "Listagem",
          active: true,
          }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true },
        { label: "Nome", key: "name", sortable: true },
        { label: "E-mail", key: "email", sortable: true },
        { label: "Criado", key: "created_at", sortable: true },
        { label: "Ações", key: "acoes", sortable: false }
      ],
      infoModal: {
        id: 'info-modal-users',
        title: '',
        codigo: '',
        email: '',
        criado: '',
      }
      }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));    
    this.notification.message = '';
    this.notification.clear;
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.users.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    clonefoot() {
      return (this.users.length > 10) ? true : false;
    },
    isHide() {
      return (this.isEditing) ? true : false;
    }
  },
  async mounted() {
    try {
      Swal.fire({
        title: "Aguarde...",
        text: "Carregando dados...",
        allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
      });
      await http.get('/empresa/usersbyempresa/'+this.currentEmpresa.id)
        .then(res => {
          this.users = res.data;
          Swal.close();
        })
        .catch((error) => {
          if(error.response.status === 403) {
            console.log('Sem permissão');
            this.notification.message = 'Usuário não autorizado!';
          }
        });
    } catch (error) {
      // Erros tratados aqui
    }

    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    info(item, button) {
      this.infoModal.title = item.name
      // this.infoModal.content = JSON.stringify(item, null, 2)
      this.infoModal.codigo = item.id;
      this.infoModal.email  = item.email;
      this.infoModal.criado = item.created_at;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.codigo = '';
      this.infoModal.email  = '';
      this.infoModal.criado = '';
    },
    edit(user) {
      this.userSelected = user;
      this.userSelected.role_id = user.roles[0].id;
      this.isEditing = true;
    },
    doPut(user) {
      Swal.fire({
        title: "Aguarde...",
        text: "Gravando dados...",
        allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
      });

      let promisse = http.put(`/user/${user.id}`, {
        id: user.id,
        name: user.name,
        email: user.email,
        role_id: user.role_id
      });

      promisse.then((response) => {
        if (response.status === 200) {
          this.isEditing = false;
          Swal.close();
        }
      }).catch((error) => {
        if (error.response.status === 403) {
          console.log('Sem permissão');
          this.notification.message = 'Usuário não autorizado!';
        } else {
          console.log(error.response.data);
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Erro ao gravar dados',
            html: error.response.data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
          <div class="col-md-12">
              <div v-if="!isHide" class="card">
                  <div class="card-body">
                      <h4 class="card-title">Usuários do sistema</h4>
                      <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Exibir&nbsp;
                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                            </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                            <label class="d-inline-flex align-items-center">
                                Pesquisar:
                                <b-form-input
                                v-model="filter"
                                type="search"
                                placeholder="Pesquisar..."
                                class="form-control form-control-sm ml-2"
                                ></b-form-input>
                            </label>
                            </div>
                        </div>
                        <!-- End search -->
                      </div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                            :items="users"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            :hover=true
                            :foot-clone=clonefoot
                        >
                          <template #cell(acoes)="row">
                            <!-- `data.value` is the value after formatted by the Formatter -->
                            <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
                              <template #button-content>
                                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                              </template>
                              <b-dropdown-item @click="info(row.item, $event.target)"><i class="bx ri-file-search-line"></i> Visualizar</b-dropdown-item>
                              <b-dropdown-item @click="edit(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
                              <b-dropdown-item href="#Excluir"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
                            </b-dropdown>
                          </template>                      
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                            </ul>
                            </div>
                        </div>
                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                      </div>
                  </div>
                  <!-- Info modal -->
                  <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
                    <pre>ID: {{ infoModal.codigo }}</pre>
                    <pre>E-mail: {{ infoModal.email }}</pre>
                    <pre>Criado em: {{ infoModal.criado }}</pre>
                  </b-modal>
              </div>
              <!-- end list Users -->
              <!-- start Edit User -->
              <Edit v-if="isEditing" :user="userSelected" @back="isEditing = false" @doPut="doPut"/>
              <!-- end Edit User -->
          </div>
      </div>
  </Layout>
</template>